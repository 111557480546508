import React from 'react'
import './FertilityService.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

function FertilityService() {
    return (
        <div className='fertility-main mt-5 row' id='ourservices?'>
            <div className='fertility-heading'>
                <h2 className='row fs-2 mb-3 p-1 text-center' style={{ color: "#98181E", fontWeight: "bolder" }}>We Provide Various Fertility Services Include</h2>
            </div>
            <div className='fertility-contents mt-3 row'>
                <div className='fertility-left col'>
                    <div className='service-points row'>
                        <p ><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Ovulation Induction</p>
                    </div>
                    <div className='service-points row'>
                        <p ><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Intrauterine Insemination &#40;IUI&#41;</p>
                    </div>
                    <div className='service-points row'>
                        <p ><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Intracytoplasmic Sperm Injection &#40;ICSI&#41;</p>
                    </div>
                    <div className='service-points row'>
                        <p ><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; In Vitro Fertilization &#40;IVF &#41;</p>
                    </div>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Blastocyst Culture</p>
                    </div>
                    <div className='service-points row'>
                        <p ><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Egg Freezing</p>
                    </div>
                </div >
                <div className='fertility-right col'>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Donor Egg or Sperm</p>
                    </div>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Genetic Testing</p>
                    </div>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Fertility Preservation</p>
                    </div>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Lases Assisted Hatching</p>
                    </div>
                    <div className='service-points row'>
                        <p><FontAwesomeIcon className='icon-caret' icon={faCaretRight} />&nbsp; Testicular Sperm Injection</p>
                    </div>
                </div >
            </div>
        </div >
    )
}
export default FertilityService