import React from 'react';
import './Form.css';
import banner from '../image/header image 1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import '../App.css'
import Locations from './Location';
import { useState } from 'react';
import axios from 'axios';


function Form() {

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [location, setLocation] = useState('');

    const handleSubmit = () => {
        if (name.length === 0) {
            alert("Name has left Blank!");
        }
        else if (mobile.length === 0) {
            alert("Phone Number has left Blank!");
        }
        else if (!location) {
            alert("Please select your Location");
        }
        else {
            const url = 'http://localhost/AlshifaPHP/PatientDetails.php';
            let fData = new FormData();
            fData.append('name', name);
            fData.append('mobile', mobile);
            fData.append('location', location);
            axios.post('http://localhost/AlshifaPHP/PatientDetails.php', fData).then(response => alert(response.data)).catch(error => console.log(error))
        }
    }

    return (
        <div className='form-content pt-5 mt-4 pb-3' id='home'>
            {/* ---------------------------content----------------------- */}
            <div className='form-main row '>
                {/* ------------------Col-1------------------------ */}
                <div className='form-text align-items-sm-center align-items-xs-center align-items-start align-items-md-center col col-12 col-sm-12 col-md px-1 text-wrap'>
                    <h2 className='fs-4' style={{ fontWeight: 'bolder', color: "#98181E" }}>Your IVF Journey, Our Priority</h2>
                    <h2 className='fs-4' style={{ color: "#98181E" }} >IVF Care Centered Around You</h2>
                </div>
                {/* -------------------Col-2----------------------- */}
                <div className='form-image col d-none  d-xl-block ' >
                    <img src={banner} alt='img' />
                </div>
                {/* ------------------Col-3----------------------- */}
                <div className='form-input col ' >
                    <form onSubmit={handleSubmit} id='booknow'>
                        <label>BOOK A FREE CONSULTATOIN!</label>
                        <FontAwesomeIcon className='icon-form' icon={faUserGroup} />
                        <input
                            className='form-control' type='text' name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name" />
                        <FontAwesomeIcon className='icon-form' icon={faPhone} />
                        <input
                            className='form-control' type='number' name="name" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Your Phone Number' />
                        <FontAwesomeIcon className='icon-form' icon={faLocation} />
                        <select name="location" id="name" value={location} onChange={(e) => setLocation(e.target.value)} className='form-control' >
                            {
                                Locations.map((list) => {
                                    return <option>{list.name}</option>
                                })
                            }
                        </select>
                        <button type='submit' style={{ backgroundColor: '#98181E' }} name='submit' className='text-white btn mt-2'>Sent Request</button>
                        <span className='d-flex gap-2'><input type='checkbox' checked /><label >  Nova IVF Fertility <span>Privacy Policy</span></label></span>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Form