import React from 'react'
import './Cities.css'


function Cities() {

    const CityData = [
        {
            "image": require("../image/hospital.png"),
            "name": "Success Rates and Reputation",
        },
        {
            "image": require("../image/hospital-bed.png"),
            "name": "Patient-Centered Approach",
        },
        {
            "image": require("../image/24-7.png"),
            "name": "Transparency & Ethical Practices",
        },
        {
            "image": require("../image/hospital (1).png"),
            "name": "State-of-the-Art Facilities and Technology",
        },
        {
            "image": require("../image/experts.png"),
            "name": "Medical Expertise",
        }
    ]
    return (
        <div>
            <div id='whyalshifa' className='nova-main mt-5'>
                <h2 className='text-center' style={{ color: "#98181E", fontWeight: "bolder" }}>Why Choose us for Treatment?</h2>
            </div>
            <div className='nova-main row mt-5'>
                {
                    CityData.map((listdata) => {
                        return <div className='nova-card col'>
                            <img src={listdata.image} alt='' />
                            <h4 className='text-secondary'>{listdata.name}</h4>
                        </div>
                    })
                }
            </div>
            <div className='Nova-text text-secondary container d-flex justify-content-center align-items-center text-center mt-4'>
                <p>Alshifa Healthcare Services Pvt. Ltd is an acclaimed and NABH-accredited company. It is established in 2016 and dedicated to delivering
                    top-notch healthcare globally. It has evolved into a platform which caters to the Ministry of Health, TPAs, and patients worldwide.
                    Al-Shifa is committed to prioritizing patients, company's continuous progress, aiming to offer end-to-end medical assistance with enhanced
                    access to optimal healthcare services.</p>
            </div>



        </div>
    )
}

export default Cities




