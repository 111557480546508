import React from 'react'
import logo from '../image/al_shifa_logo.png'
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope, faGlobe, faPhoneSquare } from '@fortawesome/free-solid-svg-icons'
import '../App.css'


function Header() {
    return (

        <div className='heading-tag sticky-top' >
            {/* Nav Bar Large */}
            <div className='heading-main d-none d-md-none d-sm-none d-xs-none d-lg-flex'>
                <div className='heading-left'>
                    <div className='heading-buttons'>
                        <h6><a href='tel:7669900900'>Call For Appointment</a></h6>
                        <h6 className='heading-number'><FontAwesomeIcon className='icon' icon={faPhoneSquare} /> 7669900900</h6>
                    </div>
                </div>
                <div className='heading-center'>
                    <img className='heading-logo' src={logo} alt='logo' />
                </div>
                <div className='heading-right'>
                    <div className='heading-buttons'>
                        <h6><FontAwesomeIcon className='icon' icon={faGlobe} /><a href='https://alshifahealthcareservices.com'> www.alshifahealthcareservices.com</a></h6>
                        <h6 className='heading-number'><FontAwesomeIcon className='icon' icon={faEnvelope} /> <a href='mailto:support@alshifahealthcareservices.com'> support@alshifahealthcareservices.com</a></h6>
                    </div>
                </div>
            </div>
            {/* Nav Bar XS */}
            <div className="navbar-main col navbar d-flex d-md-flex d-sm-flex d-lg-none d-xs-flex fixed-top">
                <div className='heading-left col'>
                    <img className='heading-logo' src={logo} alt='logo' />
                </div>
                <div>
                    <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                        aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span className="dark-blue-text">
                            <FontAwesomeIcon className='icon' icon={faBars} /></span></button>
                </div>
                <div className="navbar-collapse-btns collapse navbar-collapse" id="navbarSupportedContent1">


                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item px-3  active">
                            <a className="nav-link text-white" href="#home">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link text-white" href="#whyalshifa">Why Choose us for Treatment?</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link text-white" href="#WhyFertilization">Why to choose In-Vitro Fertilization?</a>
                        </li>
                        <li className="nav-item px-3">
                            <a className="nav-link text-white" href="#testimonial">Our Specialist</a>
                        </li>
                        <li className="nav-item px-3 active">
                            <a className="nav-link text-white" href="#FAQs">FAQs<span className="sr-only">(current)</span></a>
                        </li>
                    </ul>


                </div>
            </div>
        </div >

    )
}
export default Header

