import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './SpecialistsDoctor.css'
import DoctorsDetails from './DoctorsDetails';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import '../App.css'

const SpecialistsDoctor = () => {

    const [click, setClick] = useState(false)

    const [data, setData] = useState(DoctorsDetails)

    const [isOpen, serIsopen] = useState();

    const [pop, setPop] = useState(data)

    const DoctorClicked = (targetIndex) => {
        setClick(true)
        setPop(data.filter((a, idx) => (idx === targetIndex)))
        console.log(targetIndex);
    }
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <div>
            <section id="testimonial" className="testimonials mt-5 pt-70 pb-70" >
                <div className="mt-5">
                    <h4 className="miniTitle text-center" style={{ color: "#98181E", fontWeight: "bolder" }}>OUR SPECIALISTS</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                                {
                                    DoctorsDetails.map((doctors, idx) => {
                                        return (
                                            <div className="item">
                                                <div className="shadow-effect">
                                                    <img className="img-circle" src={doctors.img} alt='img' />
                                                    <p>{doctors.description}</p>
                                                </div>
                                                <div onClick={() => (serIsopen(!isOpen))} className="testimonial-name">
                                                    <h5>{doctors.name}</h5>
                                                    <small onClick={() => DoctorClicked(idx)} className='btn btn-secondary border-white' style={{ backgroundColor: "#98181E" }}>{doctors.address}</small>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                {
                    click && <PopupWindow pop={pop} setClick={setClick} />
                }
            </section>
        </div>
    );
};

export default SpecialistsDoctor;


const PopupWindow = (props) => {

    const data = props.pop;
    return (
        <div className='Popup-main p-1'>
            {
                data.map((data) => {
                    return <div className='Popup-window container p-1 row'>
                        <div className='Popup-top m-1 col col-12'>
                            <div >
                                <img className='Popup-top-img' src={data.img} />
                            </div>
                            <div className='Popup-top-name'>
                                <div>
                                    <h1 className='fw-bold' style={{ color: "#98181E" }}>{data.name}</h1>
                                    <h3>{data.mainDesciption}</h3>
                                </div>
                                <div>
                                    <FontAwesomeIcon onClick={() => { props.setClick(false) }} className='icon-xmark' icon={faXmark} />
                                </div>
                            </div>
                        </div>
                        <div className='Popup-bottom p-3 col'>
                            <h6 className='Popup-text-about text-secondary fw-bold'><span className=' text-primary fs-4 fw-bold'> About the Doctor: </span>{data.about}</h6>
                            <div className='Popup-text-content d-flex flex-column gap-3 mt-5 text-secondary'>
                                <p> {data.test1}</p>
                                <p> {data.test2}</p>
                                <p> {data.test3}</p>
                                <p> {data.test4}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>

    );
};









// { click && < Songspage play={play} /> }


// function Songspage(props) {
//     const data = props.play

//     return (
//         <div className='clickedsong'>
//             <div className='newsongcard'>
//                 {
//                     data.map((song) => {
//                         return (

//                             <div className='singlecard'>
//                                 <div className='song-data'>
//                                     <div><img src={`data:image/png;base64,${song.songImage}`} alt='img'></img></div>
//                                     <div><h1>{song.songName}</h1><h1>Artist : {song.Artist}</h1></div>
//                                 </div>

//                                 <div>
//                                     <audio className='audio-player' controls autoplay src={`data:audio/mp3;base64,${song.Song}`} >
//                                     </audio>
//                                 </div>
//                                 <FontAwesomeIcon className='heart-like' icon={faHeart} />
//                             </div>
//                         )
//                     })

//                 }
//                 {/* <div className='singlecard'>
//                     <div className='song-data'>
//                         <div>
//                             <img src={data[0].artwork} alt={data[0].title}></img>
//                         </div>
//                         <div>
//                             <h1>{data[0].title}</h1>
//                             <h1>Artist : {data[0].artist}</h1>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <audio className='audio-player' controls autoplay src={data[0].url} >
//                     </audio>
//                 </div> */}
//             </div>
//         </div >
//     )
// }



