import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './Components/Header';
import Form from './Components/Form';
import Cities from './Components/Cities';
import SpecialistsDoctor from './Components/SpecialistsDoctor';
import FertilityService from './Components/FertilityService';
import ServicesAccordion from './Components/ServicesAccordion';
import Footer from './Components/Footer';
import IVFcarousel from './Components/IVFcarousel';




function App() {


  return (
    <div className="App">
      <div className='responsive'></div>
      <Header />
      <Form />
      <Cities />
      <IVFcarousel />
      <FertilityService />
      <SpecialistsDoctor />
      <ServicesAccordion />
      <Footer />
    </div>
  );
}

export default App;
