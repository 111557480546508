import Accordion from 'react-bootstrap/Accordion';
import './ServicesAccordion.css'
// import '../App.css'

function ServicesAccordion() {
    return (
        <div id='FAQs' className='accordion-main container mt-5'>
            <h1 style={{ color: "#98181E", fontWeight: "bolder" }} className='text-center'>FAQs</h1>
            <Accordion className='mt-5'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header >When should I seek fertility services?</Accordion.Header>
                    <Accordion.Body>
                        If you're under 35 and haven't conceived after a year, or over 35 and trying for six months without success, consider consulting a fertility specialist
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>What are common causes of infertility?</Accordion.Header>
                    <Accordion.Body>
                        Causes include ovulation disorders, issues with sperm production or delivery, fallopian tube problems, and age-related factors
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How successful are fertility treatments?</Accordion.Header>
                    <Accordion.Body>
                        Success rates vary but can be discussed based on individual circumstances. Factors like age, health, and specific fertility issues play a role
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Can I freeze my eggs for future use?</Accordion.Header>
                    <Accordion.Body>Yes, egg freezing is an option for preserving fertility, especially for those facing medical treatments that may affect fertility</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>What is In Vitro Fertilization &#40;IVF&#41;?</Accordion.Header>
                    <Accordion.Body>
                        IVF is a fertility treatment where an egg and sperm are combined outside the body, and the resulting embryo is then implanted in the uterus.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>What does the IVF process involve?</Accordion.Header>
                    <Accordion.Body>
                        It typically includes ovarian stimulation, egg retrieval, fertilization in the lab, embryo culture, and embryo transfer
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Are there risks associated with IVF?</Accordion.Header>
                    <Accordion.Body>
                        While generally safe, IVF carries some risks, such as multiple pregnancies and ovarian hyperstimulation syndrome &#40;OHSS&#41;
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Are there alternatives to traditional IVF?</Accordion.Header>
                    <Accordion.Body>
                        A process in which a tiny needle connected to a syringe is used to extract tissue and sperm cells from the testis. In the lab, the sperm is removed from the tissue and examined under a microscope. After that, it can either be utilised immediately to fertilise eggs or frozen for later use in treating infertility.                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>


    );
}

export default ServicesAccordion;


