import React from 'react'
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';

function TableContent() {

    const [data, setData] = useState([])

    useEffect(() => {
        fetch('http://localhost/AlshifaPHP/tabledata.php')
            .then(res => res.json())
            .then(data => setData(data))
            .catch(err => console.log(err))
    }, [])
    return (
        <div className='bg-secondary' style={{ height: "100vh" }}>
            <h1 className='pt-3 text-center text-white'>Patient Details</h1>
            <Table className="container mt-3" striped bordered>
                <thead>
                    <tr className='text-center'>
                        <th className="bg-black text-white ">ID</th>
                        <th className="bg-black text-white">Name</th>
                        <th className="bg-black text-white">Location</th>
                        <th className="bg-black text-white">Phone Number</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        data.map((data, index) => {
                            return <tr className='text-center'>
                                <td>{data.ID}</td>
                                <td>{data.Name}</td>
                                <td>{data.Location}</td>
                                <td>{data.PhoneNumber}</td>
                            </tr>
                        })
                    }

                </tbody>
            </Table >
        </div >
    )
}

export default TableContent