import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './IVFcarousel.css'
import { useState } from 'react';
import '../App.css'

const IVFcarousel = () => {

    const contents = [
        {
            image: require('../image/bacteria.png'),
            text: 'Blocked Fallopian Tube'
        },

        {
            image: require('../image/gynaecology.png'),
            text: 'Irregular Ovulation '
        },
        {
            image: require('../image/sperms.png'),
            text: 'Low Sperm Count'
        },
        {
            image: require('../image/pregnant.png'),
            text: 'Unexplained Infertility'
        }
    ]

    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <div>
            <section id="WhyFertilization?" className="testimonials mt-5 pt-70 pb-70" >
                <div className="mt-5">
                    <h3 className="miniTitle text-center" style={{ color: "#98181E", fontWeight: "bolder" }}>Why to choose In-Vitro Fertilization?</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                                {
                                    contents.map((images) => {
                                        return (
                                            <div className="item">
                                                <div className="shadow-effect">
                                                    <img className="img-circle" src={images.image} alt='img' />
                                                    <p>{images.text}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default IVFcarousel;