import './AdminLogin.css'


function AdminLogin() {

    return (
        <div className='adminlogin-main'>
            <form className='admin-form' action="http://localhost/AlshifaPHP/adminLogin.php" method="post">
                <div className='labels'>
                    <label className='text-white' >Username</label>
                    <input className='input-box' name='username' type='text' placeholder='Please enter your Username' />

                </div>
                <div className='labels'>
                    <label className='text-white' >Password</label>
                    <input className='input-box' type='text' name='password' placeholder='Please enter your Password' />
                </div>
                <div className='labels'>
                    <input className='button-submit' type='submit' value='Login' />
                </div>
            </form>
        </div>
    )
}

export default AdminLogin