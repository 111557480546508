import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospitalUser, faPhoneSquare } from '@fortawesome/free-solid-svg-icons'
import './Footer.css'
import '../App.css'


function Footer() {
    return (
        <div>
            <div className='footer-main mt-5'>
                <div className='row footer-container container '>
                    <div className='footer-text col'>
                        <h3>First in Care, Always for you. Your IVF Journey, Your Way.</h3>
                    </div>
                    <div className='footer-button col d-none d-lg-block d-xl-block d-xxl-block   d-md-none d-sm-none d-xs-none'>
                        <div className='col text-start'>
                            <p>Need Help? Talk to our fertility experts.</p>
                        </div>
                        <div className='col d-flex align-items-center gap-4'  >
                            <div className='footer-number'> <h6><FontAwesomeIcon className='footer-icons' icon={faPhoneSquare} /> <a href='tel: 7669900900'> 7669900900</a></h6></div>
                            <div className='footer-number'> <h6><FontAwesomeIcon className='footer-icons' icon={faHospitalUser}></FontAwesomeIcon><a href='#testimonial'>Find IVF SPECIALISTS</a> </h6></div>
                        </div>
                    </div>

                </div>


            </div>
            <div className='footer-appointment  d-lg-none row'>
                <div className='footer-col col'>
                    <FontAwesomeIcon className='icon' icon={faHospitalUser}></FontAwesomeIcon><h6>Book an Appointment</h6>
                </div>

                <div className='footer-col col'>
                    <FontAwesomeIcon className='icon' icon={faHospitalUser}></FontAwesomeIcon><a href='tel: 98111099238'><h6> Call Now</h6></a>
                </div>
            </div>
        </div>
    )
}

export default Footer