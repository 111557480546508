const DoctorsDetails = [
    {
        name: 'Dr. Shweta Mendiratta',
        description: 'Senior Consultant Obstetrics and Gynaecology at Amrita Hospital, Faridabad',
        about: "Dr. Shweta Mendiratta have 14 years of experience as an Obstetrician and Gynecologist. She holds a professorship at Amrita Hospital and is widely recognized for her dedicated patient care. Her primary areas of expertise are IVF & treating Infertility, Diagnostic Laparoscopy & Hysteroscopy, Infertility Laparoscopic Procedures, Diagnostics Laparoscopic, & Ultrasound",
        mainDesciption: 'MBBS, MS, DNB(Obstetrics & Gynecology Obstetrician), FICOG, CIMP(Obstetrics & Gynaecology) Senior Consultant – Obstetrics and Gynaecology at Amrita Hospital, Faridabad',
        address: 'Read More',
        test1: "Dr. Shweta Mendiratta is a young dynamic & experienced fertility specialist who has the capability to individualize treatment according to patient needs & affordability,   - Kavita Kumari",
        test2: "It was good to be treated with professionalism and care. Dr. Shweta made me feel that I was in safe hands. I appreciated their follow-up and willingness to answer questions also. A big thank you to all of them.- Sandhya Nair",
        test3: "She has always been very friendly. Being very helpful time of my wife first pregnancy at that time my wife was very scared but Dr. Shweta acted like a friend and supported my wife. Hence she didn’t face any problem during her pregnancy. She was always available whenever we needed any advice. - Vijay Singh",
        test4: "Dr Shweta mam is the best doctor in Faridabad. I am short of words to thank her for my smooth journey during 9 months of my pregnancy and my normal delivery. This is true that experience counts. Everyone now a days worried about their delivery whether that will be normal or C-section, but for me that was never been a concern just because of mam as I knew that I was in safe hands. She is the best doctor once can rely upon. I want to thank her team as well specially who was a big support during my labor. Thanks mam and team for this blissful journey and received upon in a form of our little bundle of joy. - Surchita Singh",
        img: require('../image/dr. shweta mendiratta.jpg')
    },
    {
        name: 'Dr. Renu Raina Sehgal',
        description: 'Fellowship of the Association of Minimal Access Surgeons of India',
        about: "Dr. Renu Raina Sehgal have more than 17 years of experience as an Obstetrician and Gynecologist. She is a Chairperson of  Obstetrics & Gynaecology Department at Artemis Hospital.Her primary areas of expertise are Fertility Treatment (IVF, IUI & OI), Gynecology Treatment, dealing with Obstetrics Issues, High-risk Pregnancies, Laparotomies, and Hysterectomies.",
        mainDesciption: 'MBBS, DNB(Obstetrics & Gynaecology), Fellowship (Advanced Gynecological Endoscopy), Fellowship of the Association of Minimal Access Surgeons of India (FMAS) Chairperson - Department of Obstetrics & Gynaecology at Artemis Hospital, Gurugram',
        address: 'Read More',
        test1: "Dr Renu Raina Sehgal is THE BEST. She is not only the best gynaecologist but also one of the best human beings I have ever met. I would love to refer her to all expecting mothers or to anyone who is looking for gynaecologist for any other reason. She is soooooo patient and explains anything n everything in detail so that all quarries and anxieties of expectant mothers can be taken care of. I personally had a wonderful, happy n healthy pregnancy and delivery only and only because of her. If one is looking for a doctor with whom one can be friendly and share all the doubts then Dr Renu Raina Sehgal at Artemis hospital, gurgaon is the best.  - Richa hody",
        test2: "I am taking treatment from Dr Renu Sehgal since July 2015 as I was facing difficulty in conceiving. I chose Artemis hospital Gurgoan because my family had positive experience with Dr. Renu as in past she treated 4 women from my relations, moreover all 4 of them had Normal delivery so my family had trust and faith in Dr. Renu.  Last month on 18 February 2017 I was blessed with a baby boy in Artemis hospital, I had to come to the hospital at night due to increased labour pains.Soon after I reached hospital Dr.Renu and Dr.Nidhi took good care of me:) , they waited patiently so I could deliver baby normally.My case was not normal and I was diagnosed with PCOD and Stone while trying to conceive, I only obeyed sincerely all her medications and advises, and all of this paid me back as I could become a mom by her help.I am grateful to get her guidance and highly recommend her to one who is having complications in pregnancy. - Aarti jonia",
        test3: "Our beautiful baby Girl, Vaibhavi was born on October 23. She is now 4 month old. I am attaching her photograph.  She is the absolute joy of our lives and without your expert care and compassion she would not be here.  I’m sure you hear this often, but I don’t think we can ever say it enough:  THANK YOU.  THANK YOU from the bottom of our hearts for being so wonderful. Thank you for treating us and our situation as individuals. Thank you for remembering us at each and every visit, for taking the time to explain all of the procedures and medications to us.  I would say you did absolute miracle by doing Normal delivery where doctors were saying that Normal delivery is not possible these days and in such a complex situation. I would like to special thanks Dr Nidhi, Dr Ashutosh and Coordinator Mamta for assisting us in this journey. You made us a family! - Vivek Sharma",
        img: require('../image/dr. renu raina sehgal.jpg')
    },
    {
        name: 'Dr. Alka Sinha',
        description: 'Associate Director and Senior Consultant',
        about: "Dr. Alka Sinha have more than 20 years of experience as an Obstetrician and Gynecologist. She is a Associate Director and Senior Consultant of Obstetrics & Gynaecology Department at BLK Super Speciality Hospital, New Delhi.  Her primary areas of expertise are High-risk Pregnancies, Laparoscopy, Hysteroscopy, Pap-smear, Maternal and Fetal Medicine.",
        mainDesciption: 'MBBS, MS, DNB(Obstetrics & Gynecology Obstetrician), FICOG, CIMP(Obstetrics & Gynaecology) Senior Consultant – Obstetrics and Gynaecology at Amrita Hospital, Faridabad',
        address: 'Read More',
        test1: "I Dr. Rahul Vohra husband of Dr. Shalini Vohra would like to thank you for excellent care and world class treatment provided by you.  - Dr. Rahul",
        test2: "Thank you so much for your kindness, sweetness, help and support. You are all appreciated very much. Thank you once again.   - Dr Sharoon",
        test3: "I am father of Dr. Geetika Khanduri discharged recently. I sincerely thanks a lot for your extreme care and affection shown to us during our stay at BLK we pray god to bless you with best of his blessing. Regards. -PC Joshi",
        img: require('../image/dr. alka sinha.jpg')
    },
    {
        name: 'Dr. Suneeta Mittal',
        description: 'Director & HOD- Obstetrics & Gynaecology at Fortis Hospital',
        about: "Dr. Suneeta Mittal have more than 40 years of experience as an Obstetrician and Gynecologist. She is a Director and HOD of Obstetrics & Gynaecology Department at Fortis Hospital, New Delhi.  Her primary areas of expertise are IVF (In Vitro Fertilization), Hysterectomy,Endocrinological Disorders, Adolescent Reproductive Health, Laparoscopic & Hysteroscopic surgeries, High-Risk Pregnancies, Fibroid Removal Surgery.",
        mainDesciption: 'MBBS, MS, DNB(Obstetrics & Gynecology Obstetrician), FICOG, CIMP(Obstetrics & Gynaecology) Senior Consultant – Obstetrics and Gynaecology at Amrita Hospital, Faridabad',
        address: 'Read More',
        test1: "I delivered my baby under her care, when she was HOD of gynecology department in AIIMS. I was surprised by the care that I received. It was world class. Every week all my statistics used to be monitored and she used to see me. It was prevention step given my history. Only allopathy medicines that I took during my entire pregnancy were vitamins and iron. I saw only good decisions taken and all my requests addressed.  She treated me like mother. I got her first appointment by writing her an email. All practices and all my experience at AIIMS clearly tells why it is a premier education institute. - Smijoshi",
        test2: "I have recently delivered my baby under her supervision. I received very precise,genuine and appropriate guidance throughout my journey of conception and pregnancy. There were complications however,  the way mam dealt, it was superb.. During interactions with her, i could understand that she has excellent knowledge about subject and utilizes to the best per patient's requirement. Must say,  She is a doc to whom one can go without giving a second thought. I had been to other docs too earlier however, i would recommend Dr. Suneeta Mittal for anything and everything in gynae.  - Avee Shrarma",
        img: require('../image/dr suneeta mittal (1).png')
    },
    {
        name: 'Dr Geeta Chadha',
        description: 'Sr. Consultant - Obstetrics & Gynaecology at Indraprastha Apollo Hospitals',
        about: "Dr. Geeta Chadha have more than 30 years of experience as an Infertility, Laparoscopy & Gynecologist. She is a Senior Consultant of Obstetrics & Gynaecology at Indraprastha Apollo Hospitals, New Delhi and at Apollo Cradle, Nehru Place.  Her primary areas of expertise are Organ preservative in menstrual disorder, Hysteroscopic surgery for gynae, Laparoscopic surgery for gynae, Reproductive surgery, In-vitro fertilization (IVF), Endometriosis, Ovulation Induction(OI), Sperm oocyte interaction, and Fertility preservation.",
        mainDesciption: 'MBBS. MD in Obstetrics & Gynaecology Sr. Consultant - Obstetrics & Gynaecology at Indraprastha Apollo Hospitals, New Delhi',
        address: 'Read More',
        test1: "I was in the Apollo Cradle under the observation of Dr Geeta Chadha. The whole process was great. The admission staff, nursing staff, and the housekeeping staff provide exceptional care. Thanks to the team, I had a great stay at the hospital. I would highly recommend Apollo to anyone interested in a high quality, professional, and friendly treatments. - Mrs. Era",
        test2: "I came to Apollo Cradle for my delivery under Dr Geeta and had the most comfy experience here. The staff here does every little bit to make you feel at ease during your stay. They are kind and very responsive. I am impressed by the hygiene that is maintained here. I extend my regards to Apollo and its staff for making the experience of delivery much more comfortable.- Mrs. Ranjila",
        test3: "Apollo Cradle, Nehru enclave offers an excellent environment for its patients. I came here for my delivery under Dr Geeta Chadha and I am thoroughly satisfied with the love and care I received here. I am taken aback by the loving and empathetic staff here. From doctors to nurses and housekeeping, everybody here is very cooperative. The hospital provides great facilities too. I would recommend it in the future. - Mrs. Phaguni",
        test4: "Apollo Cradle is a home away from home! The staff here extends care and love similar to what you receive at home. From Dr Geeta Chaddha to nursing and housekeeping, the staff here is very efficient and their commitment towards their duties is commendable. They are responsive and cater to all your needs. The food was brought to us on time and it tasted good. I felt at complete ease during my stay here. - Mrs. Kavita Singhal",
        img: require('../image/Dr._Geeta_Chadha.jpg')
    },
    {
        name: 'Dr. Anjana Singh',
        description: 'Director & HOD- Obstetrics & Gynaecology at Fortis Hospital, Noida',
        about: "Dr. Anjana Singh have more than 20 years of experience as an  Obstetrician and Gynaecologist. She is a Director & HOD of Obstetrics & Gynaecology at Fortis Hospital, Noida.  She has major experience in High-risk Pregnancy, Infertility treatment, Gynae Laparoscopy, Hysteroscopy, Vaginal Rejuvenation, and Gynae-onco Surgery",
        mainDesciption: 'MBBS, DNB Director & HOD- Obstetrics & Gynaecology at Fortis Hospital, Noida',
        address: 'Read More',
        test1: "Dr. Anjana is very polite and gentle nature. She explain things in detail. Wait time is little upper side, but once you get chance she answer all your queries, let you know every pros and cons. - Rakhi Varma",
        test2: "I visited Anjana Mam at 3 months of my pregnancy as I relocated from a different location. She was always very supportive & kind, clarified all my queries very patiently. I had a very smooth pregnancy under her guidance & am blessed with a baby Girl. - Sumi Sarkar",
        test3: "	An amazing doctor with an amazing team. She helped my mother recover fully. Right from day one Dr. Anjana made us all comfortable and even operation of my mother was smooth. Dr. Anjana helped us by replying even in odd hours. Team of doctors assisting her along with desk staff are extremely competent and helpful. Thanks a lot for the care and support. - Manish Jha",
        img: require('../image/dr. anjana singh.jpg')
    },

]

export default DoctorsDetails;