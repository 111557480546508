const Locations =

    [
        { "code": "AN", "name": "Your Location " },
        { "code": "AN", "name": "Andaman " },
        { "code": "AP", "name": "Andhra Pradesh" },
        { "code": "AR", "name": "Arunachal Pradesh" },
        { "code": "AS", "name": "Assam" },
        { "code": "BR", "name": "Bihar" },
        { "code": "CG", "name": "Chandigarh" },
        { "code": "CH", "name": "Chhattisgarh" },
        { "code": "DH", "name": "Dadra " },
        { "code": "DD", "name": "Daman" },
        { "code": "DL", "name": "Delhi" },
        { "code": "GA", "name": "Goa" },
        { "code": "GJ", "name": "Gujarat" },
        { "code": "HR", "name": "Haryana" },
        { "code": "HP", "name": "Himachal Pradesh" },
        { "code": "JK", "name": "Jammu" },
        { "code": "JH", "name": "Jharkhand" },
        { "code": "KA", "name": "Karnataka" },
        { "code": "KL", "name": "Kerala" },
        { "code": "LD", "name": "Lakshadweep" },
        { "code": "MP", "name": "Madhya Pradesh" },
        { "code": "MH", "name": "Maharashtra" },
        { "code": "MN", "name": "Manipur" },
        { "code": "ML", "name": "Meghalaya" },
    ]

export default Locations;